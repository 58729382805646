import { HeadFC } from 'gatsby'
import * as React from 'react'
import { TbArrowBackUp } from 'react-icons/tb'

import Button from '../components/Button'
import Link from '../components/Link'
import { useTranslate } from '../utils/translate'

const NotFoundPage = () => {
  const t = useTranslate()
  console.log(t('platform.headers.1.headline'))
  return (
    <main className="dark antialiased scroll-smooth leading-relaxed w-full m-auto lg:max-w-screen-lg grid grid-flow-row gap-8 pt-16 lg:pt-24">
      <h1>{t('404.title')}</h1>
      <p>{t('404.subtitle')}</p>
      <Link to="/" className="base-link">
        <Button className="flex items-center dark:hover:bg-blue dark:hover:text-white">
          <TbArrowBackUp /> <span className="ml-2">{t('goHome')}</span>
        </Button>
      </Link>
    </main>
  )
}

export default NotFoundPage

export const Head: HeadFC = ({ pageContext }) => {
  // @ts-ignore
  const translations: any = pageContext.translations
  return (
    <>
      <title>{translations['404.title']}</title>
    </>
  )
}
